import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Initial state for the auth slice
const initialState = {
  user: null,
  token: null,
  refresh: null,
  id: null,
  file: null,
  status: 'idle',
  error: null
};

// Create an asyncThunk for uploading files
export const uploadFile = createAsyncThunk(
  'file/uploadFile'
)
// Create a slice named file od the store

// Create a slice named "auth" using createSlice function
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Reducer function to set login information in the state
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      state.id = action.payload.id;
    },
    // Reducer function to set file information in the state
    setFile: (state, action) => {
      state.file = action.payload.file;
      state.status = action.payload.status;
      state.error = action.payload.error
    },
    // Reducer function to logout and clean up the state
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.refresh = null;
      state.id = null;
    }
  },
});

// Export the reducer function generated by createSlice
export const authReducer = authSlice.reducer;

// Export the actions from the created slice
export const { setLogin, setFile, setLogout } = authSlice.actions;
