import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "../../state/redux/reducer";
import { toast } from "react-toastify"
import { Box, Container, Paper, TextField, Typography, Button, MenuItem, Menu } from "@mui/material";
import { Formik } from "formik"
import { useState } from "react";
import { signIn, signUp } from "../../state/cognito"
import { usePostUserLoginMutation, usePostRegisterUserMutation } from "../../state/api";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [name, setName] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);

    const [registerUser] = usePostRegisterUserMutation();
    const [login] = usePostUserLoginMutation();

    const roleOptions = ["Student", "Teacher"];
    const levelOptions = ["Beginner", "Intermediate", "Advanced"];

    const [role, setRole] = useState("");
    const [level, setLevel] = useState("");

    const [isSignUp, setIsSignUp] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Define the function to handle the form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        setError(null);

        try {
            const session = await signIn(email, password, dispatch);


            if (session && session.AuthenticationResult.AccessToken) {
                const { data } = await login({ email: email, password: password });
                dispatch(
                    setLogin({
                        user: data.user,
                        token: session.AuthenticationResult.AccessToken,
                        refresh: session.AuthenticationResult.RefreshToken,
                        id: session.AuthenticationResult.IdToken,
                    })
                )
            }
            else {
                setError("Sign in session or AccessToken is undefined");
            }

            navigate("/");



        } catch (error) {
            setError(error.message);
        }


    }

    const handleSignup = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        try {
            await signUp(email, password);
            const { data } = await registerUser({ name: name, email: email, level: level, role: role });
            console.log(data);
            navigate('/confirm', { state: { email } });
        } catch (error) {
            alert("Sign up failed");
            setError(error.message);
        }
    }

    const handleClick = async (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = async () => {
        setAnchorEl(null);
    }

    const handleRole = async (event) => {
        setRole(event.target.value);
        handleClose();
    }

    const handleLevel = async (event) => {
        setLevel(event.target.value);
        handleClose();
    }

    return (
        <Container>
            <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5">{isSignUp ? "Sign up to create an account" : "Log in to your account"}</Typography>
                <Box component="form" onSubmit={isSignUp ? handleSignup : handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && (
                        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {isSignUp && (
                        <Box>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="confirmPassword"
                                label="Confirm Password"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                select
                                margin="normal"
                                required
                                fullWidth
                                id="role"
                                label="Select a Role"
                                name="role"
                                value={role}
                                onChange={handleRole}
                            >
                                {roleOptions.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                margin="normal"
                                required
                                fullWidth
                                id="level"
                                label="Select a Level"
                                name="level"
                                value={level}
                                onChange={handleLevel}
                            >
                                {levelOptions.map((level) => (
                                    <MenuItem key={level} value={level}>
                                        {level}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Login
                    </Button>
                </Box>
            </Paper>
            <Button
                onClick={() => setIsSignUp(!isSignUp)}
            >
                {isSignUp ? "Already have an account? Sign in" : "Need an account? Sign Up"}
            </Button>
        </Container>
    )
}

export default Login;