import { setLogin } from "./redux/reducer";
import { refreshToken } from "./cognito";
import storage from "redux-persist/lib/storage";

export const getValidAccessToken = async (dispatch, token, refresh, id, user) => {

    console.log(token, refresh, id, dispatch, user);

    if (!token || IsTokenExpired(token)){
        if (refresh) {
            const { AccessToken } = await refreshToken(refresh);

            dispatch(setLogin(
                {
                    user: user,
                    token: AccessToken,
                    refresh: refresh,
                    id: id

                }
            )
        );
        return AccessToken;
        } else {
            throw new Error("No valid refresh token. User needs to log in again");
        }
    }
    return token;
};


const IsTokenExpired = (token) => {
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return tokenPayload.exp < currentTime;
}


