import { Container, Typography, Box } from "@mui/material";
import ClickableCard from "../../components/Widgets/ClickableCard";


const Tutorials = () => {

  return (
    <Box maxWidth="sm">
        <ClickableCard title="Fundamentals" focus="fundamentals" destination="tutorials"/>
        <ClickableCard title="Perspective" focus="perspective" destination="tutorials"/>
        <ClickableCard title="Proportions" focus="proportions" destination="tutorials"/>
        <ClickableCard title="Posing" focus="posing" destination="tutorials"/>
        <ClickableCard title="Lighting" focus="lighting" destination="tutorials"/>
    </Box>
  );
}


export default Tutorials;