import { Container, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";


const Home = () => {
  return (
    <Box maxWidth="sm">
      <Typography variant="h1">
        Welcome to Alexandria
      </Typography>

      <Typography variant="p">
        The web app that allows you to discover and hone your inner artist.
      </Typography>
    </Box>
  );
}


export default Home;