import { Grid, Box, Container, Typography } from "@mui/material";


const ParagraphFormatter = (data) => {

    const paragraphs = data.paragraphs;
    return (
        <Container>
            {paragraphs.length > 0 ? (
                <Grid container spacing={3}>
                    {paragraphs.map(content => (
                        <Grid item>
                            <Typography>
                                {content.content}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            ) : (<Typography>Error rending the content of the tutorial.</Typography>)}
        </Container>

    )
}

export default ParagraphFormatter;