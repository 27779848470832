import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePostCreateTutorialMutation, usePostUploadFileMutation, usePostLinkFileMutation } from "../../state/api";
import { Typography, Container, CircularProgress, Box, Grid, Paper, TextField, Select, MenuItem, InputLabel, Button } from "@mui/material";
import ParagraphFormatter from "../../components/ParagraphFormatter";
import FileUploader from "../../components/Renderers/FileUploader"

const TutorialCreation = () => {
    const isAuth = Boolean(useSelector((state) => state.global.token));
    const token = useSelector((state) => state.global.token);
    const user = useSelector((state) => state.global.user);
    const [level, setLevel] = useState('Fundamentals');
    const [title, setTitle] = useState("");
    const [paragraphs, setParagraphs] = useState([{ content: "" }]);
    const [reference, setReference] = useState(null);

    const [postUploadFile, { data, isLoading, isSuccess, isError, error }] = usePostUploadFileMutation();
    const [postCreateTutorial] = usePostCreateTutorialMutation();

    
    const navigate = useNavigate();
    const handleSubmit = async () => {
        try{
            let fileId = null;

            if (reference){
                const {data} = await postUploadFile({ file: reference, token: token});
                fileId = data.key;
            }

            
            const references = fileId ? [{reference: fileId}] : [];

            const tutorialData = {
                title: title,
                subtitle: "No subtitle",
                author: user.name,
                level: level,
                paragraphs: paragraphs,
                references: references,
                token: token,
                user: user
            };

            await postCreateTutorial(tutorialData);

            navigate('/');
            
        } catch (error) {
            console.log(error);
        }


    }

    const handleLevelChange = (event) => {
        setLevel(event.target.value);
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }


    const handleParagraphChange = (index, event) => {
        const newParagraphs = [...paragraphs];
        newParagraphs[index].content = event.target.value;
        setParagraphs(newParagraphs);
    }

    const handleAddParagraph = () => {
        setParagraphs([...paragraphs, { content: "" }]);
    }

    const handleRemoveParagraph = (index) => {
        const newParagraphs = paragraphs.filter((_, i) => i !== index);
        setParagraphs(newParagraphs);
    }

    const handleReferenceChange = (file) => {
        setReference(file);
    }


    if (!isAuth) {
        return (
            <Container>
                <Typography color="error" variant="h6">
                    Error: You are not authorized to create tutorials
                </Typography>
            </Container>
        );
    }

    return (
        <Container>
            <Paper elevation={3}>
                <Typography variant="h4">Create a title for the title</Typography>
                <TextField id="title" label="Title" onChange={handleTitleChange} />
                <Typography variant="h4">Decide what the tutorial focuses on</Typography>
                <Select id="level" label="Level" onChange={handleLevelChange} value={level} >
                    <MenuItem value="Fundamentals">Fundamentals</MenuItem>
                    <MenuItem value="Perspective">Perspective</MenuItem>
                    <MenuItem value="Proportions">Proportions</MenuItem>
                    <MenuItem value="Posing">Posing</MenuItem>
                    <MenuItem value="Lighting">Lighting</MenuItem>
                </Select>

                <Typography variant="h4">Write up the paragraphs</Typography>
                {paragraphs.map((paragraph, index) =>
                    <Box key={index} mb={2}>
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={4}
                            value={paragraph.content}
                            onChange={(event) => handleParagraphChange(index, event)} 
                            />
                        <Button
                            color="secondary"
                            onClick={() => handleRemoveParagraph(index)}>
                            Remove Paragraph
                        </Button>
                    </Box>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddParagraph}
                >
                    Add Paragraph
                </Button>

                <Typography variant="h4">
                    Upload a reference for the tutorial
                </Typography>
                <FileUploader onChange={handleReferenceChange} />

                <Button onClick={handleSubmit}>Submit</Button>
            </Paper>
        </Container>
    );
}

export default TutorialCreation;