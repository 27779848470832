import { Box, Container, Typography, useMediaQuery } from "@mui/material"; 
import Navbar from "../../components/Navbar";
import { Outlet } from "react-router-dom";

const Layout = (userAuth) => {
    return (
        <Container>
            <Navbar userAuth={userAuth.userAuth}/>
            <Outlet />
        </Container>
    )
}

export default Layout;