import { CognitoIdentityProviderClient, InitiateAuthCommand, SignUpCommand, ConfirmSignUpCommand, AuthFlowType, UsernameAttributeType, ExplicitAuthFlowsType } from "@aws-sdk/client-cognito-identity-provider"
import { useDispatch } from "react-redux";
import { setLogin } from "./redux/reducer";
import { usePostRegisterUserMutation as registerUser } from "./api";

export const cognitoClient = new CognitoIdentityProviderClient({
    region: "ap-southeast-2"
});

export const signIn = async (username, password, dispatch) => {
    const params = {
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        ClientId: "3f3a3t8v2m51lgph1jjnar8vsg",
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
    };

    try {
        const command = new InitiateAuthCommand(params);
        const response = await cognitoClient.send(command);

        const { AccessToken, RefreshToken, IdToken } = response.AuthenticationResult;
        
        console.log("Authentication successful: ", response);
        dispatch(setLogin({
            token: AccessToken,
            refresh: RefreshToken,
            id: IdToken,
        }))

        return response;
    } catch (error) {
        console.error("Error signing in: ", error);
        throw error;
    }
};

export const signUp = async (email, password) => {
    const params = {
        ClientId: "3f3a3t8v2m51lgph1jjnar8vsg",
        Username: email,
        Password: password,
        UserAttributes: [
            {
                Name: 'email',
                Value: email
            },
        ],
    };


    try {
        const command = new SignUpCommand(params);
        const response = await cognitoClient.send(command);

        console.log("Sign up successful");
        return response;
    } catch (error) {
        console.error("Error signing up: ", error);
        throw error;
    }
};

export const confirmSignUp = async (username, code) => {
    const params = {
        ClientId: "3f3a3t8v2m51lgph1jjnar8vsg",
        Username: username,
        ConfirmationCode: code,
    };

    try {
        const command = new ConfirmSignUpCommand(params);
        const response = await cognitoClient.send(command);


        
        console.log("User confirmed successfully: ", response);
        return true;
    } catch (error) {
        console.error("Error confirming sign up: ", error);
        throw error;
    }
}

export const refreshToken = async (refresh, dispatch) => {
    const params = {
        AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
        ClientId: "3f3a3t8v2m51lgph1jjnar8vsg",
        AuthParameters: {
            REFRESH_TOKEN: refresh,
        }
    }

    try {
        const command = new InitiateAuthCommand(params);
        const response = await cognitoClient.send(command);

        const { AccessToken, IdToken } = response.AuthenticationResult;

        console.log("Refreshment successful");
        dispatch(setLogin({
            token: AccessToken,
            refresh: refresh,
            id: IdToken,
        }));

        return response;
    } catch (error) {
        console.error("Error refreshing the token");
    }
}
