import React, { useState } from "react"
import { usePostUploadFileMutation } from "../../state/api"
import { Button, TextField, CircularProgress, Typography, Input, Box } from "@mui/material"
import { useSelector } from "react-redux";

const FileUploader = ({onChange} ) => {
    const [file, setFile] = useState(null);
    const [postUploadFile, { isLoading, isSuccess, isError, error }] = usePostUploadFileMutation();
    const token = useSelector((state) => state.global.token);

    const onFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        if (onChange) {
            onChange(selectedFile);
        }
    }

    return (
        <Box>
            <Typography variant="h5"> Upload File</Typography>
            <Input type="file" onChange={onFileChange} disabled={isLoading}/> 
            {isLoading && <Typography>Uploading...</Typography>}
            {isSuccess && <Typography>File uploaded successfully!</Typography>}
            {isError && <Typography>Error uploading file: {error.message}</Typography>}
        </Box>
       
    );
}

export default FileUploader;