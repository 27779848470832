import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetTutorialLevelsQuery } from "../../state/api";
import { Typography, Container, CircularProgress, Grid } from "@mui/material";
import ClickableCard from "../../components/Widgets/ClickableCard";

const TutorialsPage = () => {
  const title = useParams();

  const { data, error, isLoading } = useGetTutorialLevelsQuery(title.title);
  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Tutorials for {title.title}
      </Typography>
      {data.Count > 0 ? (
        <Grid container spacing={3}>
          {data.Items.map(tutorial => (
            <Grid item xs={12} sm={6} md={4} key={tutorial.id}>
              <ClickableCard _id={tutorial._id} title={tutorial.title} subtitle={tutorial.subtitle} destination="tutorial" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No tutorials available.</Typography>
      )}
    </Container>
  );
}

export default TutorialsPage;