import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { setupListeners } from "@reduxjs/toolkit/query"
import { authSlice } from './state/redux/reducer';
import { configureStore } from '@reduxjs/toolkit';
import { api } from "./state/api";
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

// Creating a Redux Persist configuration object
const persistConfig = {
  key: "authKey", // The key to use for storing the data in the storage
  storage, // localstorage
  whitelist: ["auth"], // Auth slice in the whitelist
  blacklist: [],
};

// Creating a persister for Redux
const persistedReducer = persistReducer(persistConfig, authSlice.reducer);

// Configure the Redux store
const store = configureStore({
  reducer: {
    global: persistedReducer, // The "global" slice of the state managed by the "authSlice" reducer with Redux Persist
    [api.reducerPath]: api.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignorePath: ['adminApi.mutations']
      }
    }).concat(api.middleware),
});

let persistor = persistStore(store);

setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

