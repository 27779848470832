import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetDownloadFileMutation, useGetTutorialQuery } from "../../state/api";
import { Typography, Container, CircularProgress, Box, Grid, ListItem, List } from "@mui/material";
import ParagraphFormatter from "../../components/ParagraphFormatter";
const TutorialPage = () => {
    const { title } = useParams();
    const _id = title;
    const isAuth = Boolean(useSelector((state) => state.global.id));
    const token = useSelector((state) => state.global.id);


    const { data, error, isLoading } = useGetTutorialQuery({ _id: _id, token: token });

        const handleDownloads = async(fileName) => {
            try {
                console.log(fileName);

                
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/files/files/${fileName}`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                });

                if (!response.ok){
                    throw new Error("THE RESPONSE IS NOT VALID");
                }

                const data = await response.json();
                const presignedURL =  data.url;
                
                const link = document.createElement('a');
                link.href = presignedURL;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);

                link.click();
                link.remove();

                window.URL.revokeObjectURL(presignedURL);
            } catch (error) {
                console.log(error);
            }
        }
    if (!isAuth) {
        return (
            <Container>
                <Typography color="error" variant="h6">
                    Error: You are not authorized to view tutorials
                </Typography>
            </Container>
        );
    }


    if (isLoading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>

    return (
        <Container>
            <Typography variant="h2" gutterBottom>
                {data.Item.title}
            </Typography>
            <ParagraphFormatter paragraphs={data.Item.paragraphs} />

            {data.Item.references && data.Item.references.length > 0 && (
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>
                        References Used:
                    </Typography>
                    <List>
                        {data.Item.references.map((reference, index) => (
                            <ListItem key={index}>
                                <Typography variant="body1" component="span" style= {{cursor: "pointer"}}onClick={() => handleDownloads(reference.reference)}>{reference.reference}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Container>
    );
}

export default TutorialPage;