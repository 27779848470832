import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { AppBar, Toolbar, Typography, Button, Container, Menu, Tooltip, IconButton, Avatar, Box, MenuItem } from "@mui/material"
import { Link, useNavigate } from "react-router-dom";
import { setLogout } from "../state/redux/reducer";


const Navbar = (userAuth) => {
    const [auth, setAuth] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    
    useEffect (() => {
        setAuth(userAuth?.userAuth || false);
    }, [userAuth]);


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    const handleNavigate = (path) => {
        navigate(path);
        handleCloseUserMenu();
    }

    const handleLogout = () => {
        dispatch(setLogout())

        navigate('/home');
    }
    return (
        <AppBar position="static">
            <Container maxWidth="x1">
                <Toolbar disableGutters>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 800,
                            letterSpacing: '.3rem',
                            textDecoration: 'none',
                        }}>
                        Alexandria
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                        <Button color="inherit" component={Link} to="/tutorials">Tutorials</Button>
                        <Button color="inherit" component={Link} to="/practicals">Practicals</Button>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {auth ? auth && (
                            <>                      
                                <Tooltip title="Open Settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar src="public/log192.png" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}>
                                    <MenuItem onClick={() => handleNavigate('/practical/create')}>Create Practical</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('/tutorial/create')}>Create Tutorial</MenuItem>
                                    <MenuItem onClick={() => handleNavigate('/profile')}>Profile</MenuItem>
                                    <MenuItem onClick={() => handleLogout()}>Sign Out</MenuItem>
                                </Menu>
                            </>
                        ) : (
                            <Button color="inherit" component={Link} to="/login">Login</Button>
                        )}

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Navbar;

