import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmSignUp } from "../../state/cognito";
import { Typography, TextField, Button, Container, Box, Paper } from "@mui/material";


const ConfirmUserPage = () => {
    const nav = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState(location.state?.email || "");
    const [code, setCode] = useState("");


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await confirmSignUp(email, code);
            alert("Account successfully confirmed\n Sign in on the next page");
            nav('/login');
        } catch (error) {
            alert(`Failed to confirm account: ${error}`);
        }
    }
    return (

        <Container>
            <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    Confirm Account
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="Confirmation Code"
                        name="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Confirm Account
                    </Button>
                </Box>
            </Paper>

        </Container>
        // <div className="loginForm">
        //     <h2>Confirm Account</h2>
        //     <form onSubmit={handleSubmit}>
        //         <div>
        //             <input
        //                 className="inputText"
        //                 type="email"
        //                 value={email}
        //                 onChange={(e) => setEmail(e.target.value)}
        //                 placeholder="Email"
        //                 required
        //             />
        //         </div>
        //         <div>
        //             <input
        //                 className="inputText"
        //                 type="text"
        //                 value={code}
        //                 onChange={(e) => setCode(e.target.value)}
        //                 placeholder="Confirmation Code"
        //                 required />
        //         </div>
        //         <button type="submit">Confirm Account</button>
        //     </form>
        // </div>
    );

};

export default ConfirmUserPage;
