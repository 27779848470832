import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    reducerPath: "adminApi",
    tagTypes: [
        "Login",
        "User",
        "Tutorial",
        "Practical"
    ],

    endpoints: (build) => ({
        // Query to get tutorial
        getTutorial: build.query({
            query: ({_id, token }) => ({
                url: `tutorials/tutorial/${_id}`,
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            }),
            providesTags: ["Tutorial"]
        }),

        // Query to get all tutorials by level
        getTutorialLevels: build.query({
            query: (level) => ({
                url: `tutorials/tutorials/${level}`,
                method: "GET",
                params: { level }
            }),
            providesTags: ["Tutorial"]
        }),

        // Mutation to register user
        postRegisterUser: build.mutation({
            query: ({ name, email, password, role, level }) => ({
                url: `auth/registerUser`,
                method: "POST",
                body: { name, email, password, role, level }
            }),
            invalidatesTags: [""],
        }),

        // Mutation to login
        postUserLogin: build.mutation({
            query: ({ email, password }) => ({
                url: `auth/login`,
                method: "POST",
                body: { email, password }
            }),
            invalidatesTags: ["Login"]
        }),


        // Mutation to post a tutorial
        postCreateTutorial: build.mutation({
            query: ({ _id, title, subtitle, author, level, paragraphs, references, token, user }) => ({
                url: `tutorials/createTutorial`,
                method: "POST",
                body: { _id, title, subtitle, author, level, paragraphs, references, user },
                headers: { Authorization: `Bearer ${token}` },
            }),
            invalidatesTags: ["Tutorial"]
        }),

        // Mutation to upload a file
        postUploadFile: build.mutation({
            query: ({ file, token }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                url: "files/uploadFile",
                method: "POST",
                body: formData,
                headers: { Authorization: `Bearer ${token}` },
            }
        }
        }),

        // Query to grab files
        getDownloadFile: build.mutation({
            query: ({ file, token }) => ({
                url: `files/files/${file}`,
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
                params: file,
                responseHandler: async (response) => {
                    // Check if response is successful
                    if (!response.ok) {
                        throw new Error('Failed to fetch report');
                    }
                    return await response.blob(); // Convert response to a Blob
                }
            })
        })
    }),
});

export const {
    useGetTutorialQuery,
    useGetTutorialLevelsQuery,
    usePostRegisterUserMutation,
    usePostUserLoginMutation,
    usePostCreateTutorialMutation,
    usePostUploadFileMutation,
    useGetDownloadFileMutation,
} = api;