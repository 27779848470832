import { Container, Typography, Box } from "@mui/material";
import ClickableCard from "../../components/Widgets/ClickableCard";

const Practicals = () => {
  return (
    <Box maxWidth="sm">
        <ClickableCard title="Basics" level="basics"/>
        <ClickableCard title="Intermediate" level="intermediate"/>
        <ClickableCard title="Advanced" level="advanced"/>
    </Box>
  );
}


export default Practicals;