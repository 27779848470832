import { useEffect, useMemo } from "react";
import { createTheme } from "@mui/material/styles"
import { themeSettings } from './theme';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Home from './scenes/home';
import Practicals from './scenes/practicals';
import Tutorials from './scenes/tutorials';
import Layout from './scenes/layout';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import TutorialsPage from './scenes/tutorials/tutorialsPage';
import TutorialPage from "./scenes/tutorials/tutorialPage";
import Login from "./scenes/login";
import { useDispatch, useSelector } from "react-redux";
import TutorialCreation from "./scenes/tutorials/tutorialCreation";
import ConfirmUserPage from "./scenes/login/confirmUserPage";
import { getValidAccessToken } from "./state/refresh";
import { refreshToken } from "./state/cognito";

// Main app component 
function App() {
  // Create and memoize the theme based on themeSettings

  const dispatch = useDispatch();
  const token = useSelector((state) => state.global.token);
  const refresh = useSelector((state) => state.global.refresh);
  const id = useSelector((state) => state.global.id);
  const user = useSelector((state) => state.global.user);

  const theme = useMemo(() => createTheme(themeSettings()));

  const isAuth = Boolean(useSelector((state) => state.global.token));


  // Refresh the token if the token expires
  useEffect(() => {
    if (refresh && token) {
      try {
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const expiresIn = (tokenPayload.exp * 1000) - Date.now() - 60000;

        if (expiresIn <= 0){
          console.log("TOKEN EXPIRED. REFRESHING");
          getValidAccessToken(dispatch).catch(error => {
            console.error("Failed to refresh token", error);
          });
        }
        else{
          const timer = setTimeout(async() => {
            try {
              const response = await getValidAccessToken(dispatch, token, refresh, id, user);
              console.log("Token refreshed", response);
            } catch (error) {
              console.error("Failed to refresh token")
            }

          }, expiresIn);
          return () => clearTimeout(timer);
        }

      } catch (error) {
        console.error("Failed to refresh");
      }

    }
  }, [token, refresh, dispatch]);


  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path='/' element={<Navigate to="/home" replace />} />
          <Route element={<Layout userAuth={isAuth} />}>
            <Route path='/home' element={<Home />} />
            <Route path='/practicals' element={<Practicals />} />
            <Route path="/profile" element={<Navigate to="/profile" replace />} />
            <Route path='/tutorials' element={<Tutorials />} />
            <Route path='/tutorials/:title' element={<TutorialsPage />} />
            <Route path="/tutorial/:title" element={<TutorialPage />} />
            <Route path="/tutorial/create" element={<TutorialCreation />} />
            <Route path="/login" element={<Login />} />
            <Route path="/confirm" element={<ConfirmUserPage />} />
          </Route>
        </Routes>
      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
