import React from 'react';
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const ClickableCard = ({title, _id, level, focus, subtitle, destination}) => {




    const levels = {
        basics: "Sharpen the fundamentals with these simple exercises",
        intermediate: "Practice the more complex topics with these intermediate practicals",
        advanced: "Challenge yourself with these difficult and extensive activities",
    }

    const focuses = {
        fundamentals: "Learn the ropes or refresh the fundamentals of any visual art",
        perspective: "Discover how perspective affects all drawings",
        proportions: "Learn the proportions of the human body and how to transform it",
        posing: "Focus on the form, gesture, and pose of a character",
        lighting: "Study how lighting can evoke depth within a drawing",
    }

    const description = level ? levels[level] : focuses[focus] || "Description Not Available";
    
    return (
        <Card component={Link} to={`/${destination}/${_id ? `${_id}` : `${title}`}`} 
        sx={{ 
            textDecoration: 'none',
            borderRadius: 2,
            boxShadow: 3,
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 6,
            },
            backgroundColor: '#f5f5f5'
        }}>
            <CardContent>
                <Typography variant="h3" component="div" sx={{ mb: 2 }}>
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description !== "Description Not Available" ? description : subtitle}
                </Typography>
            </CardContent>
        </Card>
    );
};

ClickableCard.propTypes = {
    title: PropTypes.string.isRequired,
    level: PropTypes.oneOf(['basics', 'intermediate', 'advanced']),
    focus: PropTypes.oneOf(['fundamentals', 'perspective', 'proportions', 'posing', 'lighting']),
};

export default ClickableCard;